import { useMemo } from "react";
import { useListReviewsContent } from "@tecnobit-srl/content-types-list-reviews/lazy";
import { useCarouselReviewsContent } from "@tecnobit-srl/content-types-carousel-reviews/lazy";
const useReviewsContentTypes = () => {
  const listReviews = useListReviewsContent();
  const carouselReviews = useCarouselReviewsContent();
  return useMemo(
    () => ({
      // ...yourPackage,
      ...listReviews,
      ...carouselReviews
    }),
    [
      // yourPackage,
      listReviews,
      carouselReviews
    ]
  );
};
export {
  useReviewsContentTypes
};
